
import {get, postJ} from "@/request";
import {IPage} from "@/apis/page";
import {IBannerCreate, IBannerList, IDict} from "@/apis/decorate/types";
import {IPurchaseOrder, IPurchaseOrderQuery, IPurchaseOrderQueryDetail} from "@/apis/purchase/types";

/**
 * 采购订单-列表查询
 * @param param
 */
export const purchaseOrderQueryApi = (param: IPurchaseOrderQuery) => get("/mall/purchaseOrder/query", param)

/**
 * 采购明细-列表查询
 * @param param
 */
export const purchaseOrderQueryDetailApi = (param: IPurchaseOrderQueryDetail) => get("/mall/purchaseOrder/query/detail", param)


/**
 * 采购订单-创建
 * @param param
 */
export const purchaseOrderCreateApi = (data: IPurchaseOrder) => postJ<any[]>("/mall/purchaseOrder/create", data)


/**
 * 采购订单-撤销
 * @param param
 */
export const purchaseRevokeApi = (data: any) => postJ("/mall/purchaseOrder/revoke", data)


/**
 * 采购订单-修改
 * @param param
 */
export const purchaseOrderModifyApi = (data: IPurchaseOrder) => postJ<any[]>("/mall/purchaseOrder/modify", data)


/**
 * 采购订单-删除
 * @param id
 */
export const purchaseOrderRemoveApi = (id: string) => get("/mall/purchaseOrder/remove/" + id)


/**
 * 采购订单-提交
 * @param id
 */
export const purchaseBatchSubmissionRemoveApi = (ids: string[]) => postJ("/mall/purchaseOrder/batchSubmission" ,{ids})


/**
 * 采购订单-详情
 * @param id
 */
export const purchaseOrderDetailApi = (id: string) => get("/mall/purchaseOrder/detail/" + id)

/**
 * 采购订单-商品下拉
 * @param id
 */
export const goodsDropDownApi = () => get("/mall/goods/dropDown" )

/**
 * 采购订单-站点下拉
 * @param id
 */
export const siteQueryByListApi = () => get("/mall/site/queryByList" )


/**
 * 采购订单-列表查询导出
 * @param param
 */
export const purchaseOrderQueryExportApi = (param: IPurchaseOrderQuery) => postJ("/mall/purchaseOrder/export",  param, 'blob')

/**
 * 采购订单-采购明细
 * @param param
 */
export const purchaseOrderQueryDetailExportApi = (param: IPurchaseOrderQuery) => postJ("/mall/purchaseOrder/query/detail/export",  param, 'blob')
