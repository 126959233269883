
/**
 * @name: order
 * @author: Gzm
 * @date: 2023-07-20 10:10
 * @description：采购管理-采购订单
 * @update: 2023-07-20 10:10
 */
import {Vue, Component} from "vue-property-decorator"
import {INotice, INoticeParam} from "@/apis/notice/types"
import {ICrudOption} from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";
import {
  goodsDropDownApi,
  purchaseBatchSubmissionRemoveApi, purchaseOrderCreateApi,
  purchaseOrderDetailApi, purchaseOrderModifyApi,
  purchaseOrderQueryApi, purchaseOrderQueryExportApi,
  purchaseOrderRemoveApi, purchaseRevokeApi, siteQueryByListApi
} from "@/apis/purchase";
import {IPurchaseOrder, IPurchaseOrderQuery} from "@/apis/purchase/types";

@Component({})
export default class OrderPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: INotice[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: INoticeParam = {
    page: 1,
    limit: 10
  }
  // 表单数据
  modelForm: Partial<IPurchaseOrder> = {
    createPurchaseOrderDtlDtoList: []
  }
  repealForm = {
    id: "",
    cancelOrderReason: ""
  }


  // 新增编辑订单
  addOrderDialog = false
  addOrderDialogTitle = ''
  // 撤销弹窗
  repealDialog = false
  // 详情
  detailDialog = false
  detailForm = {}
  // 商品下拉
  goodsList = []
  // 站点下拉
  siteQueryByList = []
  // 商品
  goods = ''
  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "",
        prop: 'detail',
        hide: true,
        viewSlot: true,
      },
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
        selectable: this.rowSelectble,
        viewHide: true
      },
      {
        label: "订单编号",
        prop: "purchaseOrderSn",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true,
        slot: true,
        search: true,
      },
      {
        label: "商品种类",
        prop: "productCategory",
        align: "center",
        viewHide: true,
      },
      {
        label: "采购数量",
        prop: "purchaseQty",
        align: "center",
        viewHide: true,
      },
      {
        label: "采购日期",
        prop: "purchaseTime",
        align: "center",
        width: 180,
        search: true,
        type: "daterange",
        searchSlot: true,
      },
      {
        label: "备注",
        prop: "remarks",
        align: "center",
        overHidden: true
      },
      {
        label: "经办人",
        prop: "editName",
        align: "center",
        search: true
      },
      // {
      //   label: "状态",
      //   prop: "status",
      //   addHide: true,
      //   editHide: true,
      //   width: 150,
      //   align: 'center',
      //   search: true,
      //   type: "switch",
      //   viewHide: true,
      //   dicData: [
      //     {
      //       label: "草稿",
      //       value: 1
      //     },
      //     {
      //       label: "正式单",
      //       value: 2
      //     },
      //     {
      //       label: "撤销单",
      //       value: 3
      //     }
      //   ]
      // },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        width: 180,
        editHide: true,
        addHide: true,
        search: true,
        type: "daterange"
      },
      {
        label: "状态",
        prop: "status",
        addHide: true,
        editHide: true,
        width: 150,
        align: 'center',
        type: "switch",
        search: true,
        dicData: [
          {
            label: "草稿",
            value: 1
          },
          {
            label: "正式单",
            value: 2
          },
          {
            label: "撤销单",
            value: 3
          }
        ]
      },
      {
        label: "操作",
        slot: true,
        prop: "btn",
        width: "200",
        align: "center"
      }
    ]
  }

  rules = {
    purchaseTime: [
      {required: true, message: '请选择采购日期', trigger: 'blur'},
    ],
    purchaseQty: [
      // {required: true, message: '请输入采购数量', trigger: 'blur'},
      {required: true, validator: (rule:any, value:any, callback:any) => {
          if (value === '') {
            callback(new Error('请输入采购数量'));
          }else if(!/^[1-9]{1}[0-9]{0,5}$/.test(value)){
            callback(new Error('应大于0小于999999的正整数'));
          }
          else {
            callback();
          }
        }, trigger: 'blur'},

    ],
    cancelOrderReason: [
      {required: true, message: '请输入撤销原因', trigger: 'blur'},
    ],
    siteId: [
      {required: true, message: '请选择发往站点', trigger: 'blur'},
    ],
  }

  selectRow: any = []

  get selectIds() {
    return this.selectRow.map((item: any) => item.id)
  }

  get totalQty() {
    let num = 0
    this.modelForm.createPurchaseOrderDtlDtoList.map((item:any) => {
      console.log(item,'ssssssssssssssss')
      num = parseInt(item.purchaseQty || 0) + num
    })

    return num
  }


  getList() {
    this.tableLoading = true
    const form: IPurchaseOrderQuery = deepCopy(this.queryForm)
    if (form.purchaseTime && form.purchaseTime.length) {
      form.startTime = form.purchaseTime[0]
      form.endTime = form.purchaseTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    if (form.addTime && form.addTime.length) {
      form.createStartTime = form.addTime[0]
      form.endStartTime = form.addTime[1]
    } else {
      form.createStartTime = ""
      form.endStartTime = ""
    }
    delete form.purchaseTime
    delete form.addTime
    purchaseOrderQueryApi(form).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total;
      this.tableLoading = false
    })
  }

  rowSelectble(row: any, index: number) {
    if (row.status == 1) {
      return true
    }
    return false
  }

  openDetail(row: IPurchaseOrderQuery, index: number) {
    purchaseOrderDetailApi(row.id as string).then(e => {
      if (e) {
        this.detailForm = e
        this.detailDialog = true
      }
    })
  }

  handleEdit(row:any){
    this.goods = ''
    purchaseOrderDetailApi(row.id).then(e => {
      if (e){
        this.modelForm = e
        this.addOrderDialog = true
        this.addOrderDialogTitle = '编辑采购订单'
        this.getListDown()
      }
    })
  }

  openAdd() {
    this.modelForm = {createPurchaseOrderDtlDtoList: []}
    this.goods = ''
    this.addOrderDialog = true
    this.addOrderDialogTitle = '新增采购订单'
    this.getListDown()
  }

  getListDown(){
    goodsDropDownApi().then(e => {
      this.goodsList = e
      console.log(this.goodsList)
    })
    siteQueryByListApi().then(e => {
      this.siteQueryByList = e
    })
  }

  /**
   * 删除采购订单
   * @param row
   */
  handleRemove(row: any) {
    this.$confirm('是否确认删除该采购订单?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      purchaseOrderRemoveApi(row.id).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
    });
  }

  handleRepeal(row: any) {
    this.repealForm.id = row.id
    this.repealDialog = true
  }

  handleRepealAffirm(){
      if (!this.repealForm.cancelOrderReason){
        return this.$message.error('请输入撤销原因')
      }
      purchaseRevokeApi(this.repealForm).then(e => {
        if (e){
          this.repealDialog = false
          this.getList()
        }
      })
  }

  /**
   * 提交采购订单
   * @param row
   */
  handleSubmit(row: any) {
    this.$confirm('是否确认提交该采购订单?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      purchaseBatchSubmissionRemoveApi([row.id]).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
      this.selectRow = []
    });
  }

  /**
   * 批量提交采购订单
   * @param row
   */
  handleSubmitList() {
    if (this.selectIds.length == 0) {
      return this.$message.error("请选择数据")
    }
    this.$confirm('是否确认批量提交采购订单?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      purchaseBatchSubmissionRemoveApi(this.selectIds).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
    });
  }

  handleAffirm(){
    console.log(this.modelForm.createPurchaseOrderDtlDtoList)

    // @ts-ignore
    this.$refs['form'].validate((valid)=>{
      if(valid){
        if (this.modelForm.createPurchaseOrderDtlDtoList.length == 0){
          return this.$message.error('请选择商品')
        }
        if (this.modelForm.id){
          purchaseOrderModifyApi(this.modelForm).then(e => {
            if (e){
              this.$message.success('编辑成功！')
              this.addOrderDialog = false
              this.getList()
            }
          })
        }else {
          purchaseOrderCreateApi(this.modelForm).then(e => {
            if (e){
              this.$message.success('新增成功！')
              this.addOrderDialog = false
              this.getList()
            }
          })
        }
      }
    })
  }

  goodsAdd(){
    console.log(this.goods)
    if (!this.goods){
      return this.$message.error('请先选择商品')
    }
    this.goodsList.forEach((item:any) => {
      if (item.id == this.goods){
        console.log(item,'goodsitemgoodsitemgoodsitemgoodsitem')
        // item.siteId = ''
        // item.purchaseQty = ''
        this.modelForm.createPurchaseOrderDtlDtoList.push({
          goodsId:item.id,
          goodsName:item.goodsName,
          siteId:'',
          purchaseQty:''
        })
      }
    })
  }

  goodsRemove(index:number){
    this.modelForm.createPurchaseOrderDtlDtoList.splice(index,1)
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const form: IPurchaseOrderQuery = deepCopy(this.queryForm)
    if (form.purchaseTime && form.purchaseTime.length) {
      form.startTime = form.purchaseTime[0]
      form.endTime = form.purchaseTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    if (form.addTime && form.addTime.length) {
      form.createStartTime = form.addTime[0]
      form.endStartTime = form.addTime[1]
    } else {
      form.createStartTime = ""
      form.endStartTime = ""
    }
    delete form.purchaseTime
    delete form.addTime
    purchaseOrderQueryExportApi(form).then(e => {
      exportFile(e, "采购订单.xlsx")
    })
  }

  created() {
    this.getList()
  }
}
